import { useMemo } from 'react'

const urls = {
	//DEMANDES
	todosUrl: '/app/demandes',

	//INTERVENTIONS
	interventionsUrl: '/app/interventions',
	circuitsUrl: '/app/interventions/circuits',
	tasksUrl: '/app/interventions/tasks',
	planningUrl: '/app/interventions/planning',
	printUrl: '/app/print',

	//STAFFMEMBERS
	staffMembersUrl: '/app/staffMembers',
	staffMemberPrevisionnelUrl: '/app/staffMembers/previsionnel',
	schedulesUrl: '/app/staffMembers/schedules',
	squadsUrl: '/app/staffMembers/squads',
	shiftsUrl: '/app/staffMembers/shifts',
	shiftSchedulesUrl: '/app/staffMembers/shifts/shiftSchedules',
	daysUrl: '/app/staffMembers/days',

	// PATRIMOINE
	patrimoineUrl: '/app/patrimoine',
	patrimoineMobiliersUrbainsUrl: '/app/patrimoine/mobiliersUrbains',
	patrimoineWarehousesUrl: '/app/patrimoine/warehouses',
	patrimoineSitesUrl: '/app/patrimoine/sites',
	patrimoineMaintenancesUrl: '/app/patrimoine/maintenances',

	equipmentsUrl: '/app/patrimoine/equipments',
	equipmentTypesUrl: '/app/patrimoine/equipments/equipmentTypes',
	certificationsUrl: '/app/patrimoine/equipments/certifications',
	equipmentIssuesUrl: '/app/patrimoine/equipments/equipmentIssues',
	equipmentPrevisionnelUrl: '/app/patrimoine/equipments/previsionnel',

	dashboardUrl: '/app/dashboard',
	statsUrl: '/app/stats',
	layersUrl: '/app/layers',

	//MAP
	mapUrl: '/app/map',
	mapDashboardUrl: '/app/map/pilotage',
	mapLayersUrl: '/app/map/layers',
	mapCircuitsUrl: '/app/map/tasks',
	mapAreasUrl: '/app/map/areas',
	mapWarehousesUrl: '/app/map/warehouses',
	mapSitesUrl: '/app/map/sites',
	mapCitiesUrl: '/app/map/cities',

	//CLIENT
	clientsUrl: '/app/clients',

	//ADMIN
	adminUrl: '/app/admin',
	usersUrl: '/app/admin/users',
	servicesUrl: '/app/admin/services',
	teamsUrl: '/app/admin/teams',

	//HELP
	helpUrl: '/app/help',
	tutorialsUrl: '/app/help/tutorials',
	ticketsUrl: '/app/help/tickets',
	linksUrl: '/app/help/links',

	// APP
	mobileUrl: '/mobile',
}

export function useUrls() {
	return useMemo(() => urls, [])
}

export function getUrls() {
	return urls
}
